import { useAuthIsAuthQuery } from "./adminMeQuery.generated";
import { useEffect, useState } from "react";


export const useAuthenticated = () => {
  const [isAuth, setIsAuth] = useState<undefined | boolean>(undefined)
  const { data, loading, error } = useAuthIsAuthQuery({
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    console.log("Data is", data)
    if (data === undefined){
      return
    }
    if(data){
      console.log("Setting isauth to true")
      setIsAuth(true)
    } else {
      console.log("setting isauth to false")
      setIsAuth(false)
    }
  }, [data])
  return isAuth

};
