import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import { SuperHeroLogin } from "./superhero-login";

export const AdminRouter = () => {
  return (
    <div className={"min-h-screen w-full flex flex-col justify-center bg-blueGray-900"}>
      <div className={"flex flex-col items-center m-auto my-16"}>
        <Link to={"/admin/superhero-login"} className={"bg-indigo-800 text-blueGray-50 text-xl px-16 py-4 rounded"}>Superhero
          Login</Link>
      </div>
      <Switch>
        <Route exact path={"/admin/superhero-login"}>
          <SuperHeroLogin/>
        </Route>
      </Switch>
    </div>
  );
};