import { useAuthMutation } from "./auth.generated";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export const Auth = () => {
  let history = useHistory();
  const [key, setKey] = useState("");
  const [authMutation, { data, loading, error }] = useAuthMutation();
  const handleLogin = () => {
    authMutation({
      variables: {
        key: key,
      },
      refetchQueries: () => ["adminMeQuery", "authIsAuth"],
    })
      .then(() => {
        console.log("Success");
        history.push("/admin");
      })
      .catch((e) => {
        window.alert(e.toString());
      });
  };
  return (
    <div className={"h-screen w-screen flex justify-center bg-blueGray-900"}>
      <div className={"flex flex-col w-full mx-24 items-center my-auto bg-blueGray-700 p-16 rounded"}>
        <label className={"text-blueGray-100 text-left mr-auto pb-4 text-lg"}>Key</label>
        <input
          onChange={(e) => setKey(e.target.value)}
          className={"text-lg w-full px-4 py-2"}
          type={"text"}
        />
        <button
          onClick={() => handleLogin()}
          className={"bg-indigo-600 text-blueGray-100 px-8 py-2 text-lg rounded mt-8"}
        >
          Submit
        </button>
      </div>
    </div>
  );
};
