import React from "react";
import {Redirect, Route} from "react-router-dom";

export const PrivateRoute = ({isAuth, children, ...rest}: any) => {
  return (
    <Route {...rest}
           render={({location}) =>
             isAuth === true ? (
               children
             ) : (
               <Redirect
                 to={{pathname: "/", state: {from: location}}}
               />
             )}/>
  )
};