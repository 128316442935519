import { AllDetails, useGetDetailsQuery } from "./gql/GetDetails.generated";
import { useEffect, useState } from "react";
import { useSetIdMutation } from "./gql/SetID.generated";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useEditDomainMutation } from "./gql/Edit.generated";
import { PlanLevel } from "../../../types";

export const AllAccountDetails = () => {
  const { data, loading, error } = useGetDetailsQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    console.log("data is ", data);
  }, [data]);
  if (loading) {
    return <p>Loading</p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }
  return (
    <div className={"px-2 py-4"}>
      {data &&
        data.GetAllDetails &&
        data.GetAllDetails.slice()
          .sort((a, b) => {
            if (a.DomainIsActive && !b.DomainIsActive) {
              return 1;
            }
            if (a.NumberOfParams > b.NumberOfParams) {
              return -1;
            }
            return 0;
          })
          .map((obj, index) => {
            return (
              <div key={obj.Domain}>
                <AccountDetails key={obj.Domain} obj={obj} />
              </div>
            );
          })}
      {!data && <p>No data</p>}
    </div>
  );
};

type props = {
  obj: AllDetails;
};

enum EditType {
  Undefined,
  TrialExpiresDate,
  Plan,
  AccountActive,
}

const AccountDetails = (props: props) => {
  const [showLogin, setShowLogin] = useState(false);
  const [setIdMutation] = useSetIdMutation();
  const [showSensitive, setShowSensitive] = useState(false);
  const [showEdit, setShowEdit] = useState(EditType.Undefined);
  const [newPlan, setNewPlan] = useState<PlanLevel | undefined>(undefined);
  // This is a moment object
  const [newDate, setNewDate] = useState<any | undefined>(undefined);
  const [editDomainMutation, { data, loading, error }] = useEditDomainMutation();
  const editDomain = (
    expireDate: null | string,
    domainID: number,
    accountActive: boolean | null,
    plan: PlanLevel | null
  ) => {
    editDomainMutation({
      variables: {
        input: {
          Plan: plan,
          AccountActive: accountActive,
          DomainID: domainID,
          TrialExpires: expireDate,
        },
      },
      refetchQueries: () => ["GetDetails"],
    }).catch((e) => console.log(e));
  };

  useEffect(() => {
    console.log(newDate);
  }, [newDate]);

  const LoginAsUser = (id: number) => {
    setIdMutation({
      variables: {
        id: id,
      },
    })
      .then(() => {
        window.location.replace(
          process.env.REACT_APP_CLIENT_SITE ? process.env.REACT_APP_CLIENT_SITE : "https://google.com"
        );
      })
      .catch((err) => window.alert(err));
  };
  return (
    <>
      <div className={"text-xl bg-blueGray-900 p-6 text-blueGray-100 mb-16"} key={props.obj.Domain}>
        <p className={"bg-blue-800 text-blueGray-100 max-w-max px-6 py-2 rounded"}>{props.obj.Domain} </p>
        <p className={"py-2 mt-2"}>
          <span className={props.obj.DomainIsActive ? "text-green-300" : "text-red-300"}>
            {props.obj.DomainIsActive ? "Account Active" : "Account Not Active"}
          </span>{" "}
          - {props.obj.Plan}
        </p>
        {props.obj.OnActiveTrial && <p className={"py-2 mt-2"}>Trial Expires: {props.obj.TrialExpireDate}</p>}
        {props.obj.OnExpiredTrial && (
          <p className={"py-2 mt-2"}>Trial Expired: {props.obj.TrialExpireDate}</p>
        )}
        {props.obj.SubscriptionCancelled && (
          <p className={"py-2 mt-2 text-red-300"}>
            Subscription cancelled for an end date of {props.obj.SubscriptionCancelledDate} :(
          </p>
        )}
        <p className={"py-2"}>Number of Params: {props.obj.NumberOfParams}</p>
        <p className={"py-2"}>Number of Active Tests: {props.obj.NumberOfTests}</p>
        <button
          onClick={() => setShowLogin(!showLogin)}
          className={"border-green-900 border-4 px-4 py-1 my-8 rounded text-lg"}
        >
          {showLogin ? "hide" : "Log in as one of the users"}
        </button>
        {showLogin &&
          props.obj.Users.map((userObject, index) => {
            return (
              <div key={userObject.Name}>
                <button
                  onClick={() => LoginAsUser(userObject.ID)}
                  className={"border-pink-900 border-4 px-4 py-1 my-2 rounded text-lg"}
                >
                  Log In As {userObject.Name}
                </button>
              </div>
            );
          })}
        <div>
          <button
            onClick={() => setShowSensitive(!showSensitive)}
            className={"border-indigo-400 border-2 px-4 py-1 my-2 rounded text-lg"}
          >
            {showSensitive ? "Hide" : "Sensitive Plan Information"}
          </button>
          <br />
          {showSensitive && (
            <>
              <button
                onClick={() => setShowEdit(EditType.TrialExpiresDate)}
                className={`${
                  showEdit === EditType.TrialExpiresDate
                    ? "bg-blueGray-200 text-blueGray-800"
                    : "border-blueGray-200"
                } border-2 px-4 py-1 my-2 mr-4 rounded text-base`}
              >
                Trial Expires Date
              </button>
              <button
                onClick={() => setShowEdit(EditType.Plan)}
                className={`${
                  showEdit === EditType.Plan ? "bg-yellow-200 text-blueGray-800" : "border-yellow-200"
                } border-2 px-4 py-1 my-2 mr-4 rounded text-base`}
              >
                Plan Level
              </button>
              <button
                onClick={() => setShowEdit(EditType.AccountActive)}
                className={`${
                  showEdit === EditType.AccountActive ? "bg-red-300 text-blueGray-800" : "border-red-300"
                } border-2 px-4 py-1 my-2 mr-4 rounded text-base`}
              >
                Account Active State
              </button>
              {/* https://github.com/arqex/react-datetime */}
              {showEdit === EditType.TrialExpiresDate && (
                <div className={"mt-4 text-blueGray-900"}>
                  <Datetime onChange={(val: any) => setNewDate(val)} initialValue={new Date()} />
                  {/*<Datetime initialValue={new Date()} />*/}
                  {newDate && (
                    <p className={"my-4 text-blueGray-100"}>
                      If you're sure you want to set the new date as {newDate.format("YYYY-MM-DD HH:MM")}...
                    </p>
                  )}
                  {newDate && (
                    <button
                      onClick={() =>
                        editDomain(newDate.format("YYYY-MM-DD HH:MM"), props.obj.DomainID, null, null)
                      }
                      className={"bg-blueGray-100 px-8 py-2 rounded"}
                    >
                      Set New Date
                    </button>
                  )}
                </div>
              )}
              {showEdit === EditType.Plan && (
                <div className={"rounded-lg px-4 py-4 mt-6 border-4 border-yellow-400"}>
                  <p>
                    Which plan do you want to put this domain on? For paid plans, please send the customer a
                    dedicated link.
                  </p>
                  <div className={"mt-6"}>
                    <button
                      onClick={() => setNewPlan(PlanLevel.PlanLevelFreeForever)}
                      className={`${
                        newPlan === PlanLevel.PlanLevelFreeForever
                          ? "bg-green-200 text-blueGray-800"
                          : " border-2  border-green-200 "
                      }  px-6 py-2 rounded mr-4`}
                    >
                      {PlanLevel.PlanLevelFreeForever}
                    </button>
                    <button
                      onClick={() => setNewPlan(PlanLevel.PlanLevelBetaEvaluateNoHelping)}
                      className={`${
                        newPlan === PlanLevel.PlanLevelBetaEvaluateNoHelping
                          ? "bg-green-200 text-blueGray-800"
                          : " border-2  border-green-200 "
                      }  px-6 py-2 rounded mr-4`}
                    >
                      {PlanLevel.PlanLevelBetaEvaluateNoHelping}
                    </button>
                    <button
                      onClick={() => setNewPlan(PlanLevel.PlanLevelBetaEvaluateAndHelp)}
                      className={`${
                        newPlan === PlanLevel.PlanLevelBetaEvaluateAndHelp
                          ? "bg-green-200 text-blueGray-800"
                          : " border-2  border-green-200 "
                      } px-6 py-2 rounded mr-4`}
                    >
                      {PlanLevel.PlanLevelBetaEvaluateAndHelp}
                    </button>
                    {newPlan && (
                      <>
                        <p className={"my-8 font-medium"}>You're about to set the user's plan to {newPlan}</p>
                        <button
                          onClick={() => {
                            editDomain(null, props.obj.DomainID, null, newPlan);
                            setShowSensitive(false);
                            setShowEdit(() => EditType.Undefined);
                          }}
                          className={"bg-purple-800 px-8 py-2 rounded text-blueGray-100"}
                        >
                          I Understand, set {props.obj.Domain} to {newPlan}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
              {showEdit === EditType.AccountActive && (
                <div
                  className={`${
                    props.obj.DomainIsActive ? "border-red-400" : "border-green-400"
                  } border-4  rounded-lg px-4 py-4 mt-6`}
                >
                  {props.obj.DomainIsActive ? (
                    <>
                      <p>Goodbye, sweet prince. Or Fuck you, dumbass.</p>
                      <p className={"pt-4"}>
                        This action will result in the domain no longer serving traffic.
                      </p>
                      <button
                        onClick={() => {
                          editDomain(null, props.obj.DomainID, false, null);
                          setShowSensitive(false);
                          setShowEdit(() => EditType.Undefined);
                        }}
                        className={"bg-red-800 mt-8 px-8 py-2 rounded text-blueGray-100"}
                      >
                        I Understand, set {props.obj.Domain} to INACTIVE
                      </button>
                    </>
                  ) : (
                    <>
                      <p>WELCOME BACK MY MAN</p>
                      <p className={"pt-4"}>
                        This action will result in the domain <b>Being turned back on</b>
                      </p>
                      <button
                        onClick={() => {
                          editDomain(null, props.obj.DomainID, true, null);
                          setShowSensitive(false);
                          setShowEdit(() => EditType.Undefined);
                        }}
                        className={"bg-green-800 mt-8 px-8 py-2 rounded text-blueGray-100"}
                      >
                        I Understand, set {props.obj.Domain} to ACTIVE
                      </button>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        {error && <p className={"text-red-400 font-3xl"}>{error.message}</p>}
      </div>
    </>
  );
};
