import * as Types from '../../../../types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
  Map: any;
  NullableInt: any;
  NullableString: any;
  Upload: any;
  Link: any;
  Any: any;
  Url: any;
  Bytes: any;
};

export type AnalyticsUnblocker = {
  __typename?: 'AnalyticsUnblocker';
  RootDomainID?: Maybe<Scalars['Int']>;
  AnalyticsUnblockerActive?: Maybe<Scalars['Boolean']>;
  AnalyticsDomain?: Maybe<Scalars['Link']>;
  GoogleTagManagerID?: Maybe<Scalars['String']>;
  ShouldSwapPhoneNumbers?: Maybe<Scalars['Boolean']>;
  BeforePhoneNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  AfterPhoneNumber?: Maybe<Scalars['String']>;
};




export type ChangePasswordWithPassword = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type BeforeAfterInput = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
};

export type Test = {
  __typename?: 'Test';
  id: Scalars['ID'];
  FriendlyTestName: Scalars['String'];
  TestID: Scalars['String'];
  RunTestWhen: Array<RunExperimentWhen>;
  ExperimentComponentAttributes: Array<ExperimentComponentAttribute>;
  EndOnThisDate?: Maybe<Scalars['Time']>;
  IsActive: Scalars['Boolean'];
  CreatedAt: Scalars['Time'];
};

export type ExperimentComponentRule = {
  __typename?: 'ExperimentComponentRule';
  Content: Scalars['String'];
  VariantRule: RuleType;
};

export type ExperimentComponentAttribute = {
  __typename?: 'ExperimentComponentAttribute';
  Name: Scalars['String'];
  Weight: Scalars['Int'];
  Rules: Array<Maybe<ExperimentComponentRule>>;
};

export type StandardSignUp = {
  email: Scalars['String'];
  fullName: Scalars['String'];
  password: Scalars['String'];
  hcaptchaToken: Scalars['String'];
};

export type RootDomain = {
  __typename?: 'RootDomain';
  id?: Maybe<Scalars['ID']>;
  CreateTime?: Maybe<Scalars['Time']>;
  UpdateTime?: Maybe<Scalars['Time']>;
  DomainToTraffic?: Maybe<Scalars['Link']>;
  RootDomain?: Maybe<Scalars['Link']>;
  PlanLevel?: Maybe<PlanLevel>;
  FreeTrialExpires?: Maybe<Scalars['Time']>;
  DnsProperlySetUp?: Maybe<Scalars['Boolean']>;
  DnsValidationHostname?: Maybe<Scalars['String']>;
  DnsValidationTarget?: Maybe<Scalars['String']>;
  LocalizeForCountry?: Maybe<Scalars['Boolean']>;
  CanInvite?: Maybe<Scalars['Boolean']>;
  CanViewBilling?: Maybe<Scalars['Boolean']>;
  IsOnPaidPlan?: Maybe<Scalars['Boolean']>;
};


export type TestInput = {
  FriendlyTestName?: Maybe<Scalars['String']>;
  TestID: Scalars['String'];
  RunTestWhen?: Maybe<Array<RunExperimentWhenInput>>;
  ExperimentComponentAttributes?: Maybe<Array<Maybe<ExperimentComponentAttributeInput>>>;
  EndOnThisDate?: Maybe<Scalars['Time']>;
  IsActive: Scalars['Boolean'];
};

export type DomainSettings = {
  __typename?: 'DomainSettings';
  id: Scalars['ID'];
  localizeForCountry?: Maybe<Scalars['Boolean']>;
  discernDevice?: Maybe<Scalars['Boolean']>;
  UnsafeBeforeAfter?: Maybe<Array<Maybe<UnsafeFindAndReplace>>>;
  PureReverseProxy?: Maybe<Scalars['Boolean']>;
  AnalyticsImplementation?: Maybe<Scalars['String']>;
  GoogleAnalyticsUA?: Maybe<Scalars['String']>;
  CancelURL?: Maybe<Scalars['String']>;
  NextBillDate?: Maybe<Scalars['String']>;
  SubscriptionID?: Maybe<Scalars['String']>;
  SubscriptionPlanID?: Maybe<Scalars['String']>;
  CustomerID?: Maybe<Scalars['String']>;
  UpdatePaymentURL?: Maybe<Scalars['String']>;
  IsPendingCancellation: Scalars['Boolean'];
  CancellationPendingDate?: Maybe<Scalars['Time']>;
  IsPaused: Scalars['Boolean'];
};

export type GoogleLoginResponse = {
  __typename?: 'googleLoginResponse';
  idToken?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
};

export enum RuleType {
  AddUrlParam = 'AddUrlParam',
  RedirectToUrl = 'RedirectToUrl',
  AddCodeToHeader = 'AddCodeToHeader',
  AddCodeToFooter = 'AddCodeToFooter'
}


export type RunExperimentWhenInput = {
  Content: Scalars['String'];
  Rule: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  googleLogin?: Maybe<User>;
  standardSignUp?: Maybe<User>;
  standardLogin?: Maybe<Email>;
  verifyCache?: Maybe<Scalars['Boolean']>;
  inviteUser?: Maybe<Scalars['Boolean']>;
  changePassword?: Maybe<Scalars['Boolean']>;
  createDomain?: Maybe<RootDomain>;
  checkIfSiteIsActive?: Maybe<RootDomain>;
  registerSubdomain?: Maybe<RootDomain>;
  setDomainAsCountryAware: Scalars['Boolean'];
  setDomainAsDeviceAware: Scalars['Boolean'];
  setDomainAsReverseProxy: Scalars['Boolean'];
  DeleteDomain?: Maybe<Scalars['Boolean']>;
  setUnsafeBeforeAfter?: Maybe<Scalars['ID']>;
  SetAnalyticsImplementationtype?: Maybe<Scalars['Boolean']>;
  SetAnalyticsUA?: Maybe<Scalars['Boolean']>;
  setPlan?: Maybe<PlanLevel>;
  SetAnalyticsUnblocker?: Maybe<Scalars['Boolean']>;
  SetPhoneNumbers?: Maybe<Scalars['Boolean']>;
  AssociateParamWithNakedPage?: Maybe<Scalars['String']>;
  logout?: Maybe<Scalars['Boolean']>;
  setViewedHelp?: Maybe<Scalars['Boolean']>;
  setViewedHelpToFalse?: Maybe<Scalars['Boolean']>;
  upsertURLParam?: Maybe<UrlParams>;
  deleteParam?: Maybe<Scalars['Boolean']>;
  deleteNakedPage?: Maybe<Scalars['Boolean']>;
  deleteAllNakedPages?: Maybe<Scalars['Boolean']>;
  GetAllTests?: Maybe<Array<Maybe<Test>>>;
  UpsertTest?: Maybe<Scalars['Boolean']>;
  SetTestAsActive?: Maybe<Scalars['Boolean']>;
  DeleteTest?: Maybe<Scalars['Boolean']>;
  ChangeSubscriptionPlan?: Maybe<Scalars['Boolean']>;
  ALogin?: Maybe<Scalars['Boolean']>;
  ASetSelf?: Maybe<Scalars['Boolean']>;
  AEditAccount?: Maybe<Scalars['Boolean']>;
};


export type MutationGoogleLoginArgs = {
  input?: Maybe<GoogleLogin>;
};


export type MutationStandardSignUpArgs = {
  input?: Maybe<StandardSignUp>;
};


export type MutationStandardLoginArgs = {
  input?: Maybe<StandardLogin>;
};


export type MutationVerifyCacheArgs = {
  id?: Maybe<Scalars['Int']>;
  key: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};


export type MutationInviteUserArgs = {
  email: Scalars['String'];
  rootDomain: Scalars['Link'];
  canViewBilling: Scalars['Boolean'];
  canInvite: Scalars['Boolean'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationCreateDomainArgs = {
  input: Scalars['Link'];
  addingDomainToExistingAccount: Scalars['Boolean'];
};


export type MutationCheckIfSiteIsActiveArgs = {
  rootDomain: Scalars['Link'];
  domainToTraffic: Scalars['Link'];
};


export type MutationRegisterSubdomainArgs = {
  input: RegisterSubdomain;
};


export type MutationSetDomainAsCountryAwareArgs = {
  input: Scalars['Boolean'];
  rootDomain: Scalars['Link'];
};


export type MutationSetDomainAsDeviceAwareArgs = {
  input: Scalars['Boolean'];
  rootDomain: Scalars['Link'];
};


export type MutationSetDomainAsReverseProxyArgs = {
  input: Scalars['Boolean'];
  rootDomain: Scalars['Link'];
};


export type MutationDeleteDomainArgs = {
  input: Scalars['Link'];
};


export type MutationSetUnsafeBeforeAfterArgs = {
  rootDomain: Scalars['Link'];
  beforeAfter: BeforeAfterInputRequiredStrings;
};


export type MutationSetAnalyticsImplementationtypeArgs = {
  domain: Scalars['Link'];
  implementationType: Scalars['String'];
};


export type MutationSetAnalyticsUaArgs = {
  domain: Scalars['Link'];
  ua: Scalars['String'];
};


export type MutationSetPlanArgs = {
  input: SetPlan;
};


export type MutationSetAnalyticsUnblockerArgs = {
  input: Scalars['Boolean'];
  rootDomain: Scalars['Link'];
  gtmID: Scalars['String'];
};


export type MutationSetPhoneNumbersArgs = {
  beforeNumbers?: Maybe<Array<Scalars['String']>>;
  afterNumber: Scalars['String'];
  rootDomain: Scalars['Link'];
  ShouldSwapPhoneNumbers: Scalars['Boolean'];
};


export type MutationAssociateParamWithNakedPageArgs = {
  pagePath?: Maybe<Scalars['String']>;
  paramUUID: Scalars['String'];
  imagePagePath?: Maybe<Scalars['String']>;
};


export type MutationUpsertUrlParamArgs = {
  input: SetParam;
};


export type MutationDeleteParamArgs = {
  uuid: Scalars['String'];
};


export type MutationDeleteNakedPageArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAllNakedPagesArgs = {
  domain: Scalars['Link'];
};


export type MutationGetAllTestsArgs = {
  domain: Scalars['Link'];
};


export type MutationUpsertTestArgs = {
  domain: Scalars['Link'];
  Test: TestInput;
  IsFirstSave: Scalars['Boolean'];
};


export type MutationSetTestAsActiveArgs = {
  domain: Scalars['Link'];
  testID: Scalars['String'];
  isActive: Scalars['Boolean'];
};


export type MutationDeleteTestArgs = {
  domain: Scalars['Link'];
  testID: Scalars['String'];
};


export type MutationChangeSubscriptionPlanArgs = {
  domain: Scalars['Link'];
  plan: PlanLevel;
};


export type MutationALoginArgs = {
  key: Scalars['String'];
};


export type MutationASetSelfArgs = {
  id: Scalars['Int'];
};


export type MutationAEditAccountArgs = {
  input?: Maybe<AEditAccount>;
};

export type UnsafeFindAndReplace = {
  __typename?: 'UnsafeFindAndReplace';
  Before: Scalars['String'];
  After: Scalars['String'];
  PagePath: Scalars['String'];
};

export type GoogleLogin = {
  idToken?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  givenName: Scalars['String'];
  familyName: Scalars['String'];
};

export type UrlParamsGqlGenCopy = {
  __typename?: 'UrlParamsGqlGenCopy';
  id?: Maybe<Scalars['ID']>;
  createTime?: Maybe<Scalars['Time']>;
  updateTime?: Maybe<Scalars['Time']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  beforeAfter?: Maybe<Array<Maybe<BeforeAfter>>>;
  uuid: Scalars['String'];
};

export type SetParam = {
  ID: Scalars['String'];
  pagePath: Scalars['String'];
  uuid: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
  rootDomain: Scalars['Link'];
  oldValNewVal?: Maybe<Array<Maybe<BeforeAfterInput>>>;
  ImageReplacementValues?: Maybe<Array<Maybe<ImageBeforeAfterInput>>>;
};

export type RegisterSubdomain = {
  desiredDomainToTraffic: Scalars['String'];
  currentDomain: Scalars['String'];
};

export type UrlParams = {
  __typename?: 'UrlParams';
  id: Scalars['ID'];
  createTime?: Maybe<Scalars['Time']>;
  updateTime?: Maybe<Scalars['Time']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  beforeAfter?: Maybe<Array<Maybe<BeforeAfter>>>;
  ImageBeforeAfter?: Maybe<Array<Maybe<ImageBeforeAfter>>>;
  uuid: Scalars['String'];
  NakedPagePath?: Maybe<Scalars['String']>;
  NakedPagePathForImages?: Maybe<Scalars['String']>;
};

export type BeforeAfter = {
  __typename?: 'BeforeAfter';
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<Scalars['Boolean']>;
  rootDomain?: Maybe<Array<RootDomain>>;
  currentDomain?: Maybe<RootDomain>;
  userInformation: User;
  profile: Profile;
  domainAlerts?: Maybe<Array<Maybe<Scalars['String']>>>;
  urlParams?: Maybe<UrlParams>;
  allUrlParams?: Maybe<Array<Maybe<UrlParams>>>;
  allAccountImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  doesThisParameterPairExist?: Maybe<Array<Maybe<Scalars['String']>>>;
  getAllNakedPages?: Maybe<Array<Maybe<NakedPage>>>;
  getNakedPageByURL?: Maybe<NakedPage>;
  DomainSettings: DomainSettings;
  AnalyticsUnblockerSettings?: Maybe<AnalyticsUnblocker>;
  GetTestByID?: Maybe<Test>;
  GetAllTests?: Maybe<Array<Maybe<Test>>>;
  GetCurrentName: Scalars['String'];
  GetAllDetails: Array<AllDetails>;
  AMeQuery: Scalars['Boolean'];
};


export type QueryCurrentDomainArgs = {
  domain: Scalars['Link'];
};


export type QueryDomainAlertsArgs = {
  domain: Scalars['Link'];
};


export type QueryUrlParamsArgs = {
  uuid: Scalars['String'];
};


export type QueryAllUrlParamsArgs = {
  rootDomain: Scalars['Link'];
};


export type QueryAllAccountImagesArgs = {
  rootDomain: Scalars['Link'];
};


export type QueryDoesThisParameterPairExistArgs = {
  domain: Scalars['Link'];
  key: Scalars['String'];
  value: Scalars['String'];
};


export type QueryGetAllNakedPagesArgs = {
  domain: Scalars['Link'];
};


export type QueryGetNakedPageByUrlArgs = {
  page: Scalars['Link'];
  rootDomain: Scalars['Link'];
  paramUUID?: Maybe<Scalars['String']>;
};


export type QueryDomainSettingsArgs = {
  domain: Scalars['Link'];
};


export type QueryAnalyticsUnblockerSettingsArgs = {
  domain: Scalars['Link'];
};


export type QueryGetTestByIdArgs = {
  domain: Scalars['Link'];
  testID: Scalars['String'];
};


export type QueryGetAllTestsArgs = {
  domain: Scalars['Link'];
};

export type SetPlan = {
  domain: Scalars['Link'];
  planLevel: PlanLevel;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type UrlAndRootDomain = {
  url?: Maybe<Scalars['Link']>;
  domain?: Maybe<Scalars['Link']>;
};

export type NakedPage = {
  __typename?: 'NakedPage';
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['Link']>;
  stringArraysAvailable?: Maybe<Array<Maybe<Scalars['String']>>>;
  ImagesAvailable?: Maybe<Array<Maybe<Scalars['String']>>>;
  UpdateTime?: Maybe<Scalars['Time']>;
  CreateTime?: Maybe<Scalars['Time']>;
  Location?: Maybe<Scalars['NullableString']>;
};

export type AEditAccount = {
  Plan?: Maybe<PlanLevel>;
  TrialExpires?: Maybe<Scalars['String']>;
  AccountActive?: Maybe<Scalars['Boolean']>;
  DomainID: Scalars['Int'];
};

export type Profile = {
  __typename?: 'Profile';
  id?: Maybe<Scalars['ID']>;
  createTime?: Maybe<Scalars['Time']>;
  updateTime?: Maybe<Scalars['Time']>;
  viewedHelp?: Maybe<Scalars['Boolean']>;
  beta?: Maybe<Scalars['Boolean']>;
};

export type Yeet = {
  __typename?: 'Yeet';
  id?: Maybe<Scalars['ID']>;
};

export type ValidationRequest = {
  __typename?: 'ValidationRequest';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ExperimentComponentAttributeInput = {
  Name?: Maybe<Scalars['String']>;
  Weight?: Maybe<Scalars['Int']>;
  Rules?: Maybe<Array<Maybe<ExperimentComponentRuleInput>>>;
};


export type ImageBeforeAfterInput = {
  before: Scalars['String'];
  after: Scalars['String'];
  alt?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['Bytes']>;
  isActive?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

export type ImageBeforeAfter = {
  __typename?: 'ImageBeforeAfter';
  BeforeSource: Scalars['String'];
  AfterSource: Scalars['String'];
  AltText?: Maybe<Scalars['String']>;
};

export type UrlParamsInput = {
  key: Scalars['String'];
  val: Scalars['String'];
  page: Scalars['Link'];
};

export enum PlanLevel {
  PlanLevelNone = 'PlanLevelNone',
  PlanLevelFreeForever = 'PlanLevelFreeForever',
  PlanLevelBetaBasic = 'PlanLevelBetaBasic',
  PlanLevelBetaAdvanced = 'PlanLevelBetaAdvanced',
  PlanLevelBetaEvaluateAndHelp = 'PlanLevelBetaEvaluateAndHelp',
  PlanLevelBetaEvaluateNoHelping = 'PlanLevelBetaEvaluateNoHelping',
  PlanLevelLocalTesting = 'PlanLevelLocalTesting',
  PlanLevelLocalTestingUpgraded = 'PlanLevelLocalTestingUpgraded'
}

export type AllDetails = {
  __typename?: 'AllDetails';
  DomainIsActive: Scalars['Boolean'];
  Plan: PlanLevel;
  Domain: Scalars['String'];
  Users: Array<UserInfo>;
  NumberOfParams: Scalars['Int'];
  NumberOfTests: Scalars['Int'];
  OnActiveTrial: Scalars['Boolean'];
  OnExpiredTrial: Scalars['Boolean'];
  DomainID: Scalars['Int'];
  SubscriptionCancelled: Scalars['Boolean'];
  SubscriptionCancelledDate?: Maybe<Scalars['Time']>;
  TrialExpireDate: Scalars['Time'];
};

export type ExperimentComponentRuleInput = {
  Content?: Maybe<Scalars['String']>;
  VariantRule?: Maybe<RuleType>;
};



export type BeforeAfterInputRequiredStrings = {
  before: Scalars['String'];
  after: Scalars['String'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  Name: Scalars['String'];
  ID: Scalars['Int'];
};


export type RunExperimentWhen = {
  __typename?: 'RunExperimentWhen';
  Content: Scalars['String'];
  RunExperimentRule: Scalars['String'];
};

export type Email = {
  __typename?: 'email';
  email?: Maybe<Scalars['String']>;
};

export type Url = {
  url: Scalars['String'];
};

export type StandardLogin = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type GetDetailsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDetailsQuery = (
  { __typename?: 'Query' }
  & { GetAllDetails: Array<(
    { __typename?: 'AllDetails' }
    & Pick<Types.AllDetails, 'Domain' | 'DomainIsActive' | 'NumberOfParams' | 'NumberOfTests' | 'OnActiveTrial' | 'OnExpiredTrial' | 'Plan' | 'SubscriptionCancelled' | 'SubscriptionCancelledDate' | 'TrialExpireDate' | 'DomainID'>
    & { Users: Array<(
      { __typename?: 'UserInfo' }
      & Pick<Types.UserInfo, 'ID' | 'Name'>
    )> }
  )> }
);


export const GetDetailsDocument = gql`
    query GetDetails {
  GetAllDetails {
    Domain
    DomainIsActive
    NumberOfParams
    NumberOfTests
    OnActiveTrial
    OnExpiredTrial
    Plan
    SubscriptionCancelled
    SubscriptionCancelledDate
    TrialExpireDate
    DomainID
    Users {
      ID
      Name
    }
  }
}
    `;
export type GetDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDetailsQuery, GetDetailsQueryVariables>, 'query'>;

    export const GetDetailsComponent = (props: GetDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetDetailsQuery, GetDetailsQueryVariables> query={GetDetailsDocument} {...props} />
    );
    
export type GetDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetDetailsQuery, GetDetailsQueryVariables>
    } & TChildProps;
export function withGetDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDetailsQuery,
  GetDetailsQueryVariables,
  GetDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetDetailsQuery, GetDetailsQueryVariables, GetDetailsProps<TChildProps, TDataName>>(GetDetailsDocument, {
      alias: 'getDetails',
      ...operationOptions
    });
};

/**
 * __useGetDetailsQuery__
 *
 * To run a query within a React component, call `useGetDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDetailsQuery, GetDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDetailsQuery, GetDetailsQueryVariables>(GetDetailsDocument, baseOptions);
      }
export function useGetDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDetailsQuery, GetDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDetailsQuery, GetDetailsQueryVariables>(GetDetailsDocument, baseOptions);
        }
export type GetDetailsQueryHookResult = ReturnType<typeof useGetDetailsQuery>;
export type GetDetailsLazyQueryHookResult = ReturnType<typeof useGetDetailsLazyQuery>;
export type GetDetailsQueryResult = ApolloReactCommon.QueryResult<GetDetailsQuery, GetDetailsQueryVariables>;