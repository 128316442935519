import React from "react";

import { BrowserRouter as Router, Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { AdminRouter } from "./components/admin-area";
import { Auth } from "./components/auth";
import { PrivateRoute } from "./components/auth/PrivateRoute";
import { useAuthenticated } from "./components/auth/useAuthenticated";

function App() {
  let isAuthenticated = useAuthenticated();
  if (isAuthenticated === undefined){
    return <>Is Authenticated is undefined</>
  }
  return (
    <Router>
      <Switch>
        <PrivateRoute isAuth={isAuthenticated} path={"/admin"}>
          <AdminRouter />
        </PrivateRoute>
        <Route exact path={"/"}>
          <Auth />
        </Route>
        <Route path={"/*"}>
          <Redirect to={"/"}/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
